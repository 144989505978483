<template>
  <div class="container-fluid rounded bg-white p-5">
    <div class="row mb-3">
      <div class="col-12">
        <modal-actions @close="$router.push({ name: 'tasks' })" />
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex flex-wrap justify-content-between">
        <span class="h2 text-secondary">Modifier la tâche : quos rem voluptas</span>
        <div>
          <b-button pill>Confirmer</b-button>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-sm-12 col-md-4 my-4">
        <label>Liste initiative</label>

        <v-select :v-model="task.initiative" :options="['One', 'Two', 'Three']" />
      </div>
      <div class="col-sm-12 col-md-4 my-4">
        <label>Phase</label>

        <v-select :v-model="task.phase" :options="['One', 'Two', 'Three']" />
      </div>
      <div class="col-sm-12 col-md-4 my-4">
        <label>Type de tâche</label>

        <v-select :v-model="task.task_type" :options="['One', 'Two', 'Three']" />
      </div>
      <div class="col-sm-12 col-md-4 my-4">
        <label>Tâche ou libellé</label>

        <v-select :v-model="task.task_term" :options="['One', 'Two', 'Three']" />
      </div>
      <div class="col-sm-12 col-md-4 my-4">
        <label>Date de création</label>

        <b-input-group>
          <b-form-datepicker
            :readonly="true"
            :value="new Date()"
            valueType="format"
            placeholder="Date de création"
          ></b-form-datepicker>
        </b-input-group>
      </div>
      <div
        style="display: flex; align-items: center; justify-content: center"
        class="col-sm-12 col-md-4 my-4"
      >
        <p class="h5 w-50 d-inline-block">ETP:</p>
        <br />
        <p style="color: gray; font-size: 13px">10 JOURS</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <label>Description</label>
        <textarea
          class="form-control"
          id="exampleFormControlTextarea1"
          rows="3"
          v-model="task.description"
        ></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-4 my-4">
        <label>Responsable</label>

        <v-select :v-model="task.responsable.name" :options="['One', 'Two', 'Three']" />
      </div>
      <div class="col-sm-12 col-md-4 my-4">
        <label>Date fin prév</label>
        <b-form-datepicker
          v-model="task.provisional_end"
          class="mb-2"
        ></b-form-datepicker>
      </div>
      <div class="col-sm-12 col-md-4 my-4">
        <label>Livrable</label>

        <FilePicker />
      </div>
      <div class="col-sm-12 col-md-4 mb-3">
        <label>Statut</label>

        <v-select :v-model="task.status" :options="['One', 'Two', 'Three']" />
      </div>
      <div class="col-sm-12 col-md-4 mb-3">
        <label>Avancement</label>
        <simple-slider />
      </div>
    </div>
    <p class="mt-3">Statut d'échéance</p>
    <b-badge
      style="cursor: pointer"
      @click="deadline = !deadline"
      v-if="deadline"
      variant="danger"
      pill
      >R</b-badge
    >
    <b-badge
      style="cursor: pointer"
      @click="deadline = !deadline"
      v-else
      variant="success"
      pill
      >N</b-badge
    >

    <div class="row p-0 my-4 justify-content-between align-items-center">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <p class="m-0">Les Contributeurs</p>
        <b-button
          variant="secondary"
          class="align-btn-table"
          @click="addContributerInputs"
          ><i class="mdi mdi-plus"></i> Ajouter</b-button
        >
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ContributerTable
          :contributers="contributers"
          @cancelItem="(itemIndex) => removeContributerInputs(itemIndex)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import simpleSlider from "@/components/forms/sliders/simpleSlider";
import ContributerTable from "./components/ContributerTable.vue";
import ModalActions from "@/components/custom/ModalActions.vue";

import FilePicker from "@/components/custom/FilePicker.vue";

export default {
  components: {
    simpleSlider,
    ContributerTable,
    ModalActions,
    FilePicker,
  },
  data: () => ({
    task: {
      task_type: "Risque",
      initiative: "CRM Agent",
      phase: "Phase 1",
      task_term: "Libellé Tâche 1",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Unde, accusantium. Harum officia vero impedit nulla sequi, velit quam hic distinctio!",
      task: { url: "#", title: "Tâche 1" },
      responsable: {
        name: "S.E",
        full_name: "Steve Ewore",
        img: "https://source.unsplash.com/random/150x150",
      },
      status: "En cours",
      livrable: [
        { name: "Livrable 1", url: "#" },
        { name: "Livrable 2", url: "#" },
      ],
      progress: 70,
      deadline: `N`,
      provisional_end: "12/07/2020",
      contributers: [
        {
          name: "Contributer 1",
          comment: "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
          etp: "20 jours",
          status: "Statut 1",
          conso: 30,
        },
      ],
      actions: ["delete", "show", "edit"],
    },
    contributers: {
      tableItems: [
        {
          contributers: "Contributer 1",
          comment: "Dolorem placeat accusantium omnis.",
          etp: "10",
          status: "En cours",
          conso: 60,
          actions: ["edit", "delete"],
          editMode: true,
        },
      ],
    },
    deadline: false,
  }),
  methods: {
    addContributerInputs() {
      this.contributers.tableItems = [
        ...this.contributers.tableItems,
        {
          contributers: "",
          comment: "",
          etp: "",
          status: "",
          conso: 60,
          actions: ["edit", "delete"],
          editMode: true,
        },
      ];
    },
    removeContributerInputs(itemIndex) {
      this.contributers.tableItems = this.contributers.tableItems.filter(
        (item, index) => index !== itemIndex
      );
    },
  },
};
</script>

<style></style>
